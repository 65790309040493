import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import defaultTheme from '../themes';
import Link from '@mui/material/Link';

export default function MediaCard({ children, props }) {
  const productPageUrl = `/products/${props.id}`;

  return (
    <Card sx={{ maxWidth: 345, height: 300 }}>
      <Link href={productPageUrl}>
        <CardMedia
          sx={{ height: 140 }}
          image={props.image}
          title="green iguana"
          href={productPageUrl}
        />
      </Link>

      <CardContent>
        <Link href={productPageUrl} style={{ color: 'inherit' }} underline="hover">
          <Typography gutterBottom variant="h6" noWrap>
            {props.name}
          </Typography>
        </Link>
        <Typography noWrap variant="body2" color="text.secondary" sx={{ textAlign: "left" }} >
          Price: {props.price}
        </Typography>
      </CardContent>
      <CardActions>
        {children}
        {/* <Button size="small" variant="contained" >Edit</Button>
        <Button size="small"  variant="outlined" sx={{color: `${defaultTheme.palette.primary.danger}`}}>
          Delete
        </Button> */}
      </CardActions>
    </Card>
  );
}
