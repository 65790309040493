import { useLocation } from "react-router-dom";
import React from "react";


export class CustomError {
    constructor(Key, Value) {
      this.Key = Key;
      this.Value = Value;
    }
  };

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function GetErrRes(err){
    let errArray = [];
    for (const key in err) {
        console.log(`${key}: ${err[key]}`);
        errArray.push(new CustomError(`${key}`, `${err[key]}`));
    }
    return errArray;
}