import Login from "./login";
import { useNavigate, Navigate } from "react-router-dom";
import { useQuery } from '../../utils/helpers';
import React, { useState, useEffect } from 'react';
import { ActivateAccountApi } from '../../api/auth';
import Loading from '../loading';

// TODO remove, this demo shouldn't need to reset the theme.
export default function ActivateAccount() {
    const [errText, setErrText] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    let query = useQuery();
    let token = query.get("token");
    let uidb64 = query.get("uidb64");

    useEffect(() => {
        (async function (uidb64, token) {
            let { success, err } = await ActivateAccountApi(uidb64, token);
            if (success) {    
                setLoading(false);
                navigate("/auth/login");
            }
        })(uidb64, token);
      }, []);

    return (
        <>
            <Loading open={loading} />
            <Login />
        </>
    );
}