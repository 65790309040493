import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UpdateIcon from '@mui/icons-material/Update';
import Typography from '@mui/material/Typography';

import React, { useState, useEffect } from 'react';
import defaultTheme from '../../themes';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Loading from '../loading';

const MAXIMUM_PRODUCT_LENGTH = 256;
const MINIMUM_PRODUCT_LENGTH = 6;

const ProductValidationSchema = () => {
    return Yup.object({
        name: Yup.string()
            .required("This field is required")
            .max(
                MAXIMUM_PRODUCT_LENGTH,
                `Maximum product name length is ${MAXIMUM_PRODUCT_LENGTH} characters`
            )
            .min(
                MINIMUM_PRODUCT_LENGTH,
                `Minimum product name length is ${MINIMUM_PRODUCT_LENGTH} characters`
            ),
        description: Yup.string()
            .required("This field is required")
            .max(1024,
                `Maximum description length is ${1024} characters`
            )
            .min(
                100,
                `Minimum description length is ${100} characters`
            ),
        price: Yup.number()
            .required("This field is required")
            .positive('Must be a greater than 0'),
        tags: Yup.string()
            .required("This field is required")
    });
};

export default function ProductForm({ product, metaData, setOpenModal, submitProductHandler}) {
    const [errArr, setErrArr] = useState([]);
    const [successText, setSuccessText] = useState("");
    const [loading, setLoading] = useState(false);

    const productFormikForm = useFormik({
        initialValues: {
            id: product.id,
            name: product.name,
            description: product.description,
            price: product.price,
            tags: JSON.parse(product.tags).join(', '),
            image: null
        },
        validationSchema: ProductValidationSchema,
        onSubmit: () => handleSubmitForm(productFormikForm)
    });


    const handleSubmitForm = async (productFormikForm) => {
        setLoading(true);

        submitProductHandler(productFormikForm.values);

        setOpenModal(false);
        setLoading(false);
    };

    const onImageChange = async(e) => {
        const { files } = e.target
        productFormikForm.values.image = files[0];
    };

    return (
        <Grid item xs={12} md={8} component={Paper} elevation={6} square>
            <Loading open={loading} />

            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: `${defaultTheme.palette.primary.main}` }}>
                    <UpdateIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {metaData.formTitle}
                </Typography>
                <Box component="form" onSubmit={productFormikForm.handleSubmit} sx={{ mt: 3 }}>

                    {successText != "" ? <Alert severity="success" sx={{ mb: 2, mt: 2 }} >{successText}</Alert> : ''}

                    {errArr.map(err => (
                        <Alert severity="error" sx={{ mb: 2, mt: 2 }} >{err.Key}: {err.Value}</Alert>
                    ))}

                    <Grid container spacing={2} alignItems='center' sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Product name"
                                autoFocus
                                value={productFormikForm.values.name}
                                onChange={productFormikForm.handleChange}
                                error={
                                    productFormikForm.touched.name &&
                                    Boolean(productFormikForm.errors.name)
                                }
                                helperText={
                                    productFormikForm.touched.name && productFormikForm.errors.name
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                autoComplete="description"
                                value={productFormikForm.values.description}
                                onChange={productFormikForm.handleChange}
                                error={
                                    productFormikForm.touched.description &&
                                    Boolean(productFormikForm.errors.description)
                                }
                                helperText={
                                    productFormikForm.touched.description && productFormikForm.errors.description
                                }
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label for="productImage" style={{ display: 'inline-block', marginBottom: '5px', color: '#898983', fontSize: '14px' }}>Product image</label>
                            <input
                                id="productImage"
                                name="productImage"
                                type="file"
                                fullWidth
                                className='input-file-border'
                                onChange={onImageChange}
                                required={metaData.requireImage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                id="price"
                                label="Price"
                                value={productFormikForm.values.price}
                                onChange={productFormikForm.handleChange}
                                error={
                                    productFormikForm.touched.price &&
                                    Boolean(productFormikForm.errors.price)
                                }
                                helperText={
                                    productFormikForm.touched.price && productFormikForm.errors.price
                                }
                                required
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="tags"
                                label="tags"
                                value={productFormikForm.values.tags}
                                onChange={productFormikForm.handleChange}
                                error={
                                    productFormikForm.touched.tags &&
                                    Boolean(productFormikForm.errors.tags)
                                }
                                helperText={
                                    'Seperated by commas'
                                }
                                required
                                fullWidth
                            />
                        </Grid>

                    </Grid>

                    <Box display="flex" justifyContent="center">
                        {/* {children} */}
                        <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ mr: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                type="submit"
                                variant="outlined"
                                fullWidth
                                sx={{ ml: 1 }}
                                onClick={() => setOpenModal(false)}
                            >
                                Cancel
                            </Button>
                    </Box>
                </Box>

            </Box>
        </Grid >
    );
}