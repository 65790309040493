import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import { IsAuthenticated, ResetPassConfirmApi } from '../../api/auth';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Navigate } from "react-router-dom";
import { useQuery } from '../../utils/helpers';
import Alert from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const MAXIMUM_PASSWORD_LENGTH = 30;
const MINIMUM_PASSWORD_LENGTH = 6

const ResetPassValidationSchema = () => {
  return Yup.object({
    password: Yup.string()
      .required("This field is required")
      .max(
        MAXIMUM_PASSWORD_LENGTH,
        `Maximum password length is ${MAXIMUM_PASSWORD_LENGTH} characters`
      )
      .min(
        MINIMUM_PASSWORD_LENGTH,
        `Minimum password length is ${MINIMUM_PASSWORD_LENGTH} characters`
      ),
  });
};

// TODO remove, this demo shouldn't need to reset the theme.
export default function ResetPasswordConfirm() {
  const [errText, setErrText] = useState("");

  const navigate = useNavigate();

  let query = useQuery();
  let token = query.get("token");

  const resetPassFormikForm = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: ResetPassValidationSchema,
    onSubmit: () => handleSubmitForm(resetPassFormikForm)
  });

  const handleSubmitForm = async (resetPassFormikForm) => {
    const { password } = resetPassFormikForm.values;

    let { success, err } = await ResetPassConfirmApi(password, token);
    console.log("succedded = ", success);
    console.log("err = ", err);
    if (success) {
      navigate("/auth/login");
    }
    if (err) {
      setErrText(err.join());
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
      <Box
        sx={{
          my: 20,
          mx: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}

      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" noValidate onSubmit={resetPassFormikForm.handleSubmit} sx={{ mt: 1, width: 3 / 4 }} >
          {errText != "" ? <Alert severity="error" sx={{ mb: 2, mt: 2 }} >{errText}</Alert> : ''}

          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Enter Your New Password"
            name="password"
            autoFocus
            sx={{ mt: 5, mb: 3 }}
            value={resetPassFormikForm.values.password}
            onChange={resetPassFormikForm.handleChange}
            error={
              resetPassFormikForm.touched.password &&
              Boolean(resetPassFormikForm.errors.password)
            }
            helperText={
              resetPassFormikForm.touched.password && resetPassFormikForm.errors.password
            }
          />

          <Box display="flex" justifyContent="center">
            <Box sx={{ mr: 3 }}  >
              <Button
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="outlined"
                href="/auth/reset-password"
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Grid>
  );
}