import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#1976D2',
        danger: '#ea2a2a',
      },
      secondary: {
        main: '#FFFFFF',
        minor: '#ccd5dd',
        danger: '',
      },
      third: {
        main: '#AFAEB1',
        minor: '#6f6f70',
        danger: '',
      },
    },
  });

  export default defaultTheme;