import { useNavigate, Navigate, useParams } from "react-router-dom";
import Navbar from '../components/navbar';
import Grid from '@mui/material/Grid';
import Loading from '../components/loading';

import ".././assets/css/profile.css"
import defaultTheme from '../themes';

import { GetProduct } from "../api/product";
import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Product = () => {
    const [loading, setLoading] = useState(false);

    let { id } = useParams();

    const [product, setProduct] = useState({});

    const [tags, setTags] = useState([]);

    useEffect(() => {
        (async function () {
            setLoading(true);

            let { data, err } = await GetProduct(id);
            if (err == null) {
                setProduct(data);
                setTags(JSON.parse(data.tags));
            };
            setLoading(false);
        })();
    }, []);

    return (
        <div>
            <Navbar />
            <Grid container component="main" theme={defaultTheme} spacing={2} sx={{ width: '95%', margin: '0 auto', mt: 3 }}>
                <Loading open={loading} />
                <Grid
                    item
                    xs={10}
                    sm={6}
                    md={4}
                    fullWidth
                >
                    <img src={product.image} style={{ maxWidth: "100%", height: "90%", borderRadius: 5, boxShadow: "5px 10px 5px #bfbebd" }} />

                </Grid>

                <Grid
                    item
                    md={1}
                >
                </Grid>

                <Grid
                    item
                    xs={10}
                    sm={6}
                    md={6}
                    fullWidth
                    sx={{ textAlign: "left" }}
                >

                    <h3 style={{color: `${defaultTheme.palette.third.main}`}}>{product.name}</h3>
                    <h4  style={{color: `${defaultTheme.palette.third.main}`}}>Description</h4>
                    <p className="css-fix">{product.description}</p>
                    <h4  style={{color: `${defaultTheme.palette.third.main}`}}>Price</h4>
                    <p>{product.price}$</p>
                    <h4 style={{color: `${defaultTheme.palette.third.main}`}}>Tags</h4>

                    <Stack direction="row" spacing={1}>
                        {tags.map((tag, idx) =>
                            <Chip label={tag} key={idx}/>

                        )}

                    </Stack>

                </Grid>


            </Grid>


        </div >
    );
};

export default Product;