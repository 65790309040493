import React, { useState } from 'react';
import Navbar from '../components/navbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { toAbsoluteUrl } from '../utils/helpers'
import defaultTheme from '../themes';
import Loading from '../components/loading';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';


const styles = {
  box: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: 'White',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  header: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    lineHeight: 1.2
  },
  headerTitle: {
    display: 'block',
    fontSize: '2.5rem',
    fontWeight: 'bold'
  },
  headerContent: {
    width: '500px',
    lineHeight: 1.4
  },
  name: {
    color: '#2d8e61aeddfc',
  }
}

const Home = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Navbar />
      <Grid container component="main" theme={defaultTheme}>
        <Loading open={loading} />

        <Grid
          item
          xs={12}
        >
          <Box style={styles.box} sx={{
            backgroundImage: `url(${toAbsoluteUrl('/media/header4.jpg')})`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 500,
          }}>
            <Box style={styles.overlay}>
              <div style={styles.header}>
                <span style={styles.headerTitle}>Why Wait? GrowIT</span>
                <span style={styles.headerTitle}>Right Now!</span>
                <p style={styles.headerContent}>Make your awesome business idea a reality with GrowIT, Grow your business with us by reaching your clients easily.</p>
              </div>
            </Box>
          </Box>

          <Box sx={{ mb: 10, }}>

            <Typography variant="h3" component="h3" sx={{ mt: 10, mb: 3, fontWeight: 'bold' }}>
              How it Works
            </Typography>
            <Typography sx={{ color: '#99a1b7', fontWeight: 'bold', width: '40%', margin: '0 auto' }}>Save thousands to millions of bucks by using single tool for different amazing and great useful admin</Typography>

            <Grid container sx={{ mt: 10, }}>
              <Grid item
                md={4} sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Skeleton variant="rectangular" width={300} height={300} />
              </Grid>
              <Grid item
                md={4} sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2  }}>
                <Skeleton variant="rectangular" width={300} height={300} />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'center', mb: 2  }}
                md={4} sm={12} >
                <Skeleton variant="rectangular" width={300} height={300} />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mb: 3, backgroundColor: '#13263C', padding: 3 }}>
            <Typography variant="h3" component="h3" sx={{ mt: 5, mb: 3, fontWeight: 'bold', color: 'white' }}>
              We Make Things Better
            </Typography>
            <Typography sx={{ color: '#99a1b7', fontWeight: 'bold', width: '40%', margin: '0 auto', color: '#4B4D5C' }}>Save thousands to millions of bucks by using single tool
              for different amazing and great useful admin</Typography>


            <Grid container sx={{ mt: 5, }}>
              <Grid item
                md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton variant="circular" width={250} height={250} sx={{ background: '#bac1d3' }} />
              </Grid>
              <Grid item
                md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton variant="circular" width={250} height={250} sx={{ background: '#bac1d3' }} />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }}
                md={12} >
                <Skeleton variant="circular" width={250} height={250} sx={{ background: '#bac1d3' }} />
              </Grid>
            </Grid>

            <Typography sx={{ color: '#99a1b7', fontWeight: 'bold', width: '40%', margin: '0 auto', mt: 5 }}>
              “When you care about your topic, you’ll write about it in a
              more powerful, emotionally expressive way “
            </Typography>
            <Typography sx={{ color: '#99a1b7', fontWeight: 'bold', mt: 1 }}>
              <span style={styles.name}>Marcus Levy</span>, KeenThemes CEO
            </Typography>
          </Box>

          <Box sx={{ mb: 3, }}>

            <Typography variant="h3" component="h3" sx={{ mt: 10, mb: 3, fontWeight: 'bold' }}>
              Our Great Team
            </Typography>
            <Typography sx={{ color: '#99a1b7', fontWeight: 'bold', width: '55%', margin: '0 auto' }}>
              It’s no doubt that when a development takes longer to complete, additional costs to
              integrate and test each extra feature creeps up and haunts most of us.
            </Typography>

            <Grid container sx={{ mt: 10, mb: 10 }}>
              <Grid item
                md={4} sx={{ display: 'grid', justifyItems: 'center' }}>
                <Skeleton variant="circular" width={250} height={250} />
                <Typography variant="h5" component="hh54" sx={{ mt: 1, fontWeight: 'bold', color: '#3f96fc' }}>
                  Mena Rezk
                </Typography>
                <Typography variant="h6" component="h6" sx={{ color: '#a0a7bb' }}>
                  Marketing Manager
                </Typography>
              </Grid>
              <Grid item
                md={4} sx={{ display: 'grid', justifyItems: 'center' }}>
                <Skeleton variant="circular" width={250} height={250} />
                <Typography variant="h5" component="h5" sx={{ mt: 1, fontWeight: 'bold', color: '#3f96fc' }}>
                  Nader Nabil
                </Typography>
                <Typography variant="h6" component="h6" sx={{  color: '#a0a7bb' }}>
                  Devleopment Lead
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'grid', justifyItems: 'center' }}
                md={4} >
                <Skeleton variant="circular" width={250} height={250} />
                <Typography variant="h5" component="h5" sx={{ mt: 1, fontWeight: '600', color: '#3f96fc' }}>
                  Guess who
                </Typography>
                <Typography variant="h6" component="h6" sx={{ color: '#a0a7bb' }}>
                  Will know
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Grid>
      </Grid>
    </div>
  );
};

export default Home;