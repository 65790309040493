import axios from "./axios";

const COMMON_URL = "/common";

export const GetCounteries = async () => {
    try {
        const { data: response } = await axios.get(
            COMMON_URL + '/country/',
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        
        return response;
    } catch (err) {
        if (err) {
            alert("No Server Response: ", err);
        } 
    }
};

export const GetRegions= async () => {
    try {
        const { data: response } = await axios.get(
            COMMON_URL + '/region/',
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        
        return response;
    } catch (err) {
        if (err) {
            alert("No Server Response: ", err);
        } 
    }
};