import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useFormik } from "formik";
import * as Yup  from "yup";

import {IsAuthenticated, LoginApi} from '../../api/auth';
import { useNavigate, Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const MAXIMUM_PASSWORD_LENGTH = 30;
const MINIMUM_PASSWORD_LENGTH = 6

const loginValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .max(
        MAXIMUM_PASSWORD_LENGTH,
        `Maximum password length is ${MAXIMUM_PASSWORD_LENGTH} characters`
      )
      .min(
        MINIMUM_PASSWORD_LENGTH,
        `Minimum password length is ${MINIMUM_PASSWORD_LENGTH} characters`
      )
  });
};

// TODO remove, this demo shouldn't need to reset the theme.

export default function Login() {
  const [errText, setErrText] = useState("");
  const loginFormikForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: () => handleSubmitForm(loginFormikForm)
  });
  

  const handleSubmitForm = async (loginFormikForm) => {
    const { email, password } = loginFormikForm.values;

    let errRes = await LoginApi({email: email, pwd: password});
    setErrText(errRes);

    if (IsAuthenticated()) {
      return <Navigate to="/" />;
    }
  };

  if (IsAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={loginFormikForm.handleSubmit} sx={{ mt: 1 }}>
          {errText != "" ? <Alert severity="error" sx={{ mb: 2, mt: 2 }} >{errText}</Alert>: '' }

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={loginFormikForm.values.email}
            onChange={loginFormikForm.handleChange}
            error={
              loginFormikForm.touched.email &&
              Boolean(loginFormikForm.errors.email)
            }
            helperText={
              loginFormikForm.touched.email && loginFormikForm.errors.email
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={{ mb: 5 }}
            value={loginFormikForm.values.password}
            onChange={loginFormikForm.handleChange}
            error={
              loginFormikForm.touched.password &&
              Boolean(loginFormikForm.errors.password)
            }
            helperText={
              loginFormikForm.touched.password && loginFormikForm.errors.password
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3, p: 1.5 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="reset-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Grid>
  );
}