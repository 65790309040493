import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import { IsAuthenticated, ResetPassApi } from '../../api/auth';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import React, { useState, useEffect } from 'react';
import Loading from '../loading';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ResetPassValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("This field is required"),
  });
};

// TODO remove, this demo shouldn't need to reset the theme.
export default function ResetPassword() {
  const [alert, setAlert] = useState({type: "", text: ""});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const resetPassFormikForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ResetPassValidationSchema,
    onSubmit: () => handleSubmitForm(resetPassFormikForm)
  });

  const handleSubmitForm = async (resetPassFormikForm) => {
    setLoading(true);
    const { email } = resetPassFormikForm.values;
    
    let { success, err }  = await ResetPassApi(email);
    if (success) {
      console.log("success : ", success);
      setAlert({
        type: "success",
        text: "Please check your email for verification link"
      });

      // navigate("/auth/reset-password/confirm");
    } else {
      setAlert({
        type: "error",
        text: err
      });
    }
    setLoading(false);
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
      <Loading open={loading}/>
      <Box
        sx={{
          my: 20,
          mx: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}

      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password ?
        </Typography>

        {alert.text != "" ? <Alert severity={alert.type} sx={{ mb: 2, mt: 2,  width: 3 / 4 }} >{alert.text}</Alert> : ''}

        <Box component="form" noValidate onSubmit={resetPassFormikForm.handleSubmit} sx={{ mt: 1, width: 3 / 4 }} >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{ mt: 5, mb: 3 }}
            value={resetPassFormikForm.values.email}
            onChange={resetPassFormikForm.handleChange}
            error={
              resetPassFormikForm.touched.email &&
              Boolean(resetPassFormikForm.errors.email)
            }
            helperText={
              resetPassFormikForm.touched.email && resetPassFormikForm.errors.email
            }
          />

          <Box display="flex" justifyContent="center">
            <Box sx={{ mr: 3 }}  >
              <Button
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="outlined"
                href="login"
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Grid>
  );
}