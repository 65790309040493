import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { GetCounteries } from '../../api/common';
import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import defaultTheme from '../../themes';
import { useFormik } from "formik";
import * as Yup from "yup";
import { IsAuthenticated, RegisterApi } from '../../api/auth';
import { useNavigate, Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { GetErrRes, CustomError } from '../../utils/helpers'
import Loading from '../loading';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const MAXIMUM_PASSWORD_LENGTH = 30;
const MINIMUM_PASSWORD_LENGTH = 8;

const MAXIMUM_USERNAME_LENGTH = 30;
const MINIMUM_USERNAME_LENGTH = 6;

const RegisterValidationSchema = () => {
  return Yup.object({
    username: Yup.string()
      .required("This field is required")
      .max(
        MAXIMUM_USERNAME_LENGTH,
        `Maximum username length is ${MAXIMUM_USERNAME_LENGTH} characters`
      )
      .min(
        MINIMUM_USERNAME_LENGTH,
        `Minimum username length is ${MINIMUM_USERNAME_LENGTH} characters`
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .max(
        MAXIMUM_PASSWORD_LENGTH,
        `Maximum password length is ${MAXIMUM_PASSWORD_LENGTH} characters`
      )
      .min(
        MINIMUM_PASSWORD_LENGTH,
        `Minimum password length is ${MINIMUM_PASSWORD_LENGTH} characters`
      ),
    userType: Yup.string()
      .required("User Type option is required"),
  });
};

export default function Register() {
  const [errArr, setErrArr] = useState([]);
  const [successText, setSuccessText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const registerFormikForm = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      userType: "",
      country: 0,
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: () => handleSubmitForm(registerFormikForm)
  });

  const [selectedCountry, setSelectedCountry] = useState('');
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const response = await GetCounteries();
      setCountries(response);
    };
    fetchData();
  }, []);

  const handleCountryChange = (event) => {
    registerFormikForm.values.country = event.target.value;
    setSelectedCountry(event.target.value);
  };

  const handleSubmitForm = async (registerFormikForm) => {
    setLoading(true);
    const { email, password, username, userType, country } = registerFormikForm.values;

    let req = {
      email: email,
      pwd: password,
      username: username,
      userType: userType,
      country: parseInt(country),
    }

    let { success, err } = await RegisterApi(req);
    if (success) {
      setErrArr([]);
      setSuccessText("Confirmation email sent to you, check you inbox");
      // navigate("/auth/login");
    } else {
      setErrArr(GetErrRes(err));
      setSuccessText("");

    }
    setLoading(false);
  };
  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Loading open={loading} />

      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: `${defaultTheme.palette.primary.main}` }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={registerFormikForm.handleSubmit} sx={{ mt: 3 }}>

          {successText != "" ? <Alert severity="success" sx={{ mb: 2, mt: 2 }} >{successText}</Alert> : ''}

          {errArr.map(err => (
            <Alert severity="error" sx={{ mb: 2, mt: 2 }} >{err.Key}: {err.Value}</Alert>
          ))}

          <Grid container spacing={2} alignItems='center' sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="username"
                required
                fullWidth
                id="username"
                label="Retailer / Brand name"
                autoFocus
                value={registerFormikForm.values.username}
                onChange={registerFormikForm.handleChange}
                error={
                  registerFormikForm.touched.username &&
                  Boolean(registerFormikForm.errors.username)
                }
                helperText={
                  registerFormikForm.touched.username && registerFormikForm.errors.username
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={registerFormikForm.values.email}
                onChange={registerFormikForm.handleChange}
                error={
                  registerFormikForm.touched.email &&
                  Boolean(registerFormikForm.errors.email)
                }
                helperText={
                  registerFormikForm.touched.email && registerFormikForm.errors.email
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                sx={{ mb: 4 }}
                value={registerFormikForm.values.password}
                onChange={registerFormikForm.handleChange}
                error={
                  registerFormikForm.touched.password &&
                  Boolean(registerFormikForm.errors.password)
                }
                helperText={
                  registerFormikForm.touched.password && registerFormikForm.errors.password
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCountry}
                  label="Country"
                  onChange={handleCountryChange}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {countries.map(item => {
                    return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ justifyContent: 'space-around' }}
                  required
                  value={registerFormikForm.values.userType}
                  onChange={registerFormikForm.handleChange}
                  error={
                    registerFormikForm.touched.userType &&
                    Boolean(registerFormikForm.errors.userType)
                  }

                >
                  <Grid container spacing={2} alignItems='center'  >
                    <Grid item xs={6}>
                      <FormControlLabel
                        name="userType"
                        sx={{ border: 1, borderColor: `${defaultTheme.palette.secondary.minor}`, borderRadius: 1, py: 0.5, m: 0, width: '100%', }} value="BR" control={<Radio />} label="Brand" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        name="userType"
                        sx={{ border: 1, borderColor: `${defaultTheme.palette.secondary.minor}`, borderRadius: 1, py: 0.5, m: 0, width: '100%', }} value="RE" control={<Radio />} label="Retailer" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
              <FormHelperText sx={{ color: `${defaultTheme.palette.primary.danger}` }} id="my-helper-text">{registerFormikForm.touched.userType && registerFormikForm.errors.userType}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="website"
                fullWidth
                id="website"
                label="Website"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, p: 1.5 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Copyright sx={{ mt: 5 }} />

      </Box>
    </Grid>


  );
}