import axios from "./axios";
import jwt_decode from "jwt-decode";

const PRODUCT_URL = "/product/";


export const GetProduct = async (id) => {
    try {
        let token = localStorage.getItem("access_token");

        const response = await axios.get(
            PRODUCT_URL + `${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const UpdateProduct = async (product) => {
    try {
        let token = localStorage.getItem("access_token");

        let tags = JSON.stringify(product.tags.split(','));
        var formData = new FormData();
        
        if (!!product.image) {
            formData.append("image", product.image);
        }
        formData.append("name", product.name);
        formData.append("tags", tags);
        formData.append("price", product.price);
        formData.append("description", product.description);

        const response = await axios.put(
            PRODUCT_URL + `${product.id}` + '/',
            formData,
            {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const CreateProduct = async (product) => {
    try {
        let token = localStorage.getItem("access_token");

        let tags = JSON.stringify(product.tags.split(','));
        var formData = new FormData();
        
        if (!!product.image) {
            formData.append("image", product.image);
        }
        formData.append("name", product.name);
        formData.append("tags", tags);
        formData.append("price", product.price);
        formData.append("description", product.description);

        const response = await axios.post(
            PRODUCT_URL,
            formData,
            {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const DeleteProduct = async (id) => {
    try {
        let token = localStorage.getItem("access_token");

        const response = await axios.delete(
            PRODUCT_URL + `${id}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};