import axios from "./axios";
import jwt_decode from "jwt-decode";

const AUTH_URL = "/user/";
const PRODUCT_URL = "/product/";


export const GetUser = async () => {
    try {
        let token = localStorage.getItem("access_token");

        const response = await axios.get(
            AUTH_URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const GetProducts = async () => {
    try {
        let token = localStorage.getItem("access_token");

        const response = await axios.get(
            PRODUCT_URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const BulkCreateProduct = async (file) => {
    try {
        let token = localStorage.getItem("access_token");

        var formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(
            PRODUCT_URL + `bulk/`,
            formData,
            {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};

export const UploadUserImg = async (fileObj) => {
    try {
        let token = localStorage.getItem("access_token");

        var formData = new FormData();
        formData.append("file", fileObj.file);
        formData.append("name", fileObj.name);

        const response = await axios.put(
            AUTH_URL + `image/`,
            formData,
            {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": "Bearer " + token
                },
                withCredentials: true,
            }
        );
        return { data: response.data, err: null };
    } catch (err) {
        return { data: null, err: err?.response };
    }
};