import { useNavigate, Navigate } from "react-router-dom";
import Navbar from '../components/navbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { toAbsoluteUrl } from '../utils/helpers'
import Button from '@mui/material/Button';

import ".././assets/css/profile.css"
import defaultTheme from '../themes';
import MediaCard from "../components/mediaCard";
import { GetUser, GetProducts, BulkCreateProduct, UploadUserImg } from "../api/profile";
import { UpdateProduct, CreateProduct, DeleteProduct } from "../api/product";

import React, { useState, useEffect } from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';

import Loading from '../components/loading';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactFileReader from "react-file-reader";
import Modal from '@mui/material/Modal';
import ProductForm from '../components/product/productForm';

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [products, setProducts] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [productToEdit, setProductToEdit] = useState({});

    useEffect(() => {
        (async function () {
            let { data, err } = await GetUser();
            if (err == null) {
                setUser(data);
            };

            ({ data, err } = await GetProducts());
            if (err == null) {
                setProducts(data);
            };
        })();
    }, []);

    const deleteProduct = async (id) => {
        let { data, err } = await DeleteProduct(id);
        if (err == null) {
            // if deleted succefully, get products again
            ({ data, err } = await GetProducts());
            if (err == null) {
                setProducts(data);
            };
        };
    };

    const fileUploadHandler = async (e) => {
        setLoading(true);

        const { files } = e.target

        let { data, err } = await BulkCreateProduct(files[0]);
        if (err == null) {
            // if created succefully, get products again
            ({ data, err } = await GetProducts());
            if (err == null) {
                setProducts(data);
            };
        };
        e.target.value = null;
        setLoading(false);
    }

    const uploadProfileImg = async (files) => {
        handleUserImg('profile', files);
    };
    const uploadCoverImg = async (files) => {
        handleUserImg('cover', files);
    };

    const handleUserImg = async (name, files) => {
        setLoading(true);
        let { data, err } = await UploadUserImg({ name: name, file: files.fileList[0] });
        if (err == null) {
            // if img uploaded succefully, get user again
            ({ data, err } = await GetUser());
            if (err == null) {
                setUser(data);
            };
        };
        setLoading(false);
    };

    const EditProductHandler = async (productToUpdate) => {
        setLoading(true);

        let { data, err } = await UpdateProduct(productToUpdate);
        if (err == null) {
            // if product updated succefully, get products again
            ({ data, err } = await GetProducts());
            if (err == null) {
                setProducts(data);
            };
        }
        setLoading(false);
    } ;

    const AddProductHandler = async (productToCreate) => {
        setLoading(true);
        console.log("productToCreate = ", productToCreate)
        let { data, err } = await CreateProduct(productToCreate);
        if (err == null) {
            // if product created succefully, get products again
            ({ data, err } = await GetProducts());
            if (err == null) {
                setProducts(data);
            };
        }
        setLoading(false);
    } ;

    return (
        <div>
            <Navbar />
            <Grid container component="main" theme={defaultTheme} spacing={4} sx={{ width: '95%', margin: '0 auto', mb: 5 }}>
                <Loading open={loading} />
                <Grid
                    item
                    xs={12}
                >
                    <Box sx={{
                        backgroundImage: `url(${toAbsoluteUrl(user.cover_image == '' ? '/media/cover.jpg' : user.cover_image)})`,
                        backgroundSize: 'cover',
                        position: 'relative',
                        height: 300,
                    }}>

                        {/* just to upload cover photo */}
                        <ReactFileReader
                            fileTypes={[".png", ".jpg", ".webp"]}
                            base64={true}
                            handleFiles={uploadCoverImg}

                        >
                            <div className='cover'></div>
                        </ReactFileReader>


                        <Box className="profile-photo">
                            <ReactFileReader
                                fileTypes={[".png", ".jpg", ".webp"]}
                                base64={true}
                                handleFiles={uploadProfileImg}
                            >
                                <img src={toAbsoluteUrl(user.profile_image == '' ? '/media/profile.png' : user.profile_image)} title="Change Photo" />
                            </ReactFileReader>
                        </Box>

                        <Box className="profile-details"

                            sx={{
                                display: { xs: 'none', sm: 'block' },

                                position: 'absolute',
                                bottom: 0,
                                left: 170,
                                mb: 5,
                            }}>
                            <Box component="span" sx={{
                                display: 'block',
                                fontSize: { xs: 14, sm: 20, md: 28 },
                                fontFamily: 'cursive',
                                fontWeight: 'bold',
                                color: `${defaultTheme.palette.secondary.main}`,
                            }}>{user.user_name}</Box>

                            <Box component="span" sx={{
                                display: 'block',
                                fontSize: { xs: 10, sm: 16, md: 18 },
                                textAlign: 'left',
                                color: `${defaultTheme.palette.secondary.main}`,
                            }}>{user.country}</Box>

                        </Box>
                        
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                position: 'absolute',
                                bottom: 0, right: 100, mb: 5, mr: 5,
                                ":hover": {
                                    bgcolor: `${defaultTheme.palette.primary.main}`,
                                }
                            }}
                            onClick={() => { setOpenAddModal(true); }}
                        >
                            Add Product
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                bottom: 0, right: 0, mb: 5, mr: 5,
                                bgcolor: `${defaultTheme.palette.secondary.main}`, color: `${defaultTheme.palette.primary.main}`,
                                ":hover": {
                                    bgcolor: `${defaultTheme.palette.primary.main}`,
                                    color: `${defaultTheme.palette.secondary.main}`
                                }
                            }}
                        >
                            Chat
                        </Button>
                    </Box>

                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <Accordion sx={{ background: `${defaultTheme.palette.secondary.main}` }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom variant="h6" sx={{ mb: 0 }}>
                                Add Multiple Products
                            </Typography>
                        </AccordionSummary>
                        <Divider light />

                        <AccordionDetails>
                            <Typography noWrap variant="body2" color="text.secondary" sx={{ textAlign: "left", mb: 2 }} >
                                Columns must be in the same order
                            </Typography>
                            <TableContainer component={Paper} sx={{ border: "1px solid black" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >Product Name</TableCell>
                                            <TableCell align="center">Description</TableCell>
                                            <TableCell align="center">Image url</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                            <TableCell align="right">Tags</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >Product Name</TableCell>
                                            <TableCell align="center">Product desc</TableCell>
                                            <TableCell align="center">https://image-url.com</TableCell>
                                            <TableCell align="right">126.6</TableCell>
                                            <TableCell align="right">slim, pants</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Divider light sx={{
                                mt: 2
                            }} />
                            <Box sx={{
                                mt: 2,
                                display: 'flex',
                                alignItems: 'left',
                            }}>
                                <input type="file" accept=".xlsx" onChange={fileUploadHandler} />
                            </Box>

                        </AccordionDetails>
                    </Accordion>
                </Grid>



                {products.map((product, idx) =>
                    <Grid
                        item
                        xs={10}
                        sm={6}
                        md={4}
                        lg={3}
                        key={idx}
                    >
                        <MediaCard props={product} >
                            <Button size="small" variant="contained" onClick={() => { setOpenEditModal(true); setProductToEdit(product) }}>Edit</Button>
                            <Button size="small" variant="outlined"
                                sx={{ color: `${defaultTheme.palette.primary.danger}` }}
                                onClick={() => deleteProduct(product.id)}
                            >
                                Delete
                            </Button>
                        </MediaCard>

                    </Grid>
                )}

                {/* Edit Product form modal */}
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ overflowY: 'scroll', height: '95%', margin: 'auto' }}
                >
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ProductForm product={productToEdit} metaData={{formTitle:'Edit Product', requireImage: false}} setOpenModal={setOpenEditModal} submitProductHandler={EditProductHandler}>
                        </ProductForm>
                    </Box>
                </Modal>
                
                {/* Add Product form modal */}
                <Modal
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ overflowY: 'scroll', height: '95%', margin: 'auto' }}
                >
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ProductForm product={ {
                                id: "",
                                name: "",
                                description: "",
                                price: 0,
                                tags: JSON.stringify([]),
                                image: null
                            }} metaData={{formTitle:'Add Product',  requireImage: true}} setOpenModal={setOpenAddModal} submitProductHandler={AddProductHandler}>
                        </ProductForm>
                    </Box>
                </Modal>

            </Grid>

        </div >
    );
};

export default Profile;