import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthLayout from "./views/auth/layout";
import Home from "./views/home";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ResetPassword from "./components/auth/resetPass";
import ResetPasswordConfirm from "./components/auth/resetPassConfirm";
import ActivateAccount from "./components/auth/activate";
import Profile from "./views/profile";
import Product from "./views/product";


import { IsAuthenticated } from './api/auth';
import { useNavigate, Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {
  if (!IsAuthenticated()) {
    // user is not authenticated
    return <Navigate to="/auth" />;
  }
  return children;
};


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}

          <Route path="/" element={
              <Home />
          }
          />

          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          />

          <Route path="/products">
            <Route path=":id" element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
            />
            {/* <Route path=":id/edit" element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
            /> */}
          </Route>


          <Route path="/auth" element={<Navigate to="/auth/login" />} />
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="*" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="reset-password/confirm" element={<ResetPasswordConfirm />} />
            <Route path="activate" element={<ActivateAccount />} />
            {/* <Route path="*" element={<NoPage />} /> */}
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
