import axios from "./axios";
import jwt_decode from "jwt-decode";

const AUTH_URL = "/user";
const LOGIN_URL = AUTH_URL + "/token/";
const REGISTER_URL = AUTH_URL + "/register/";
const RESET_PASS_URL = AUTH_URL + "/password-reset/";
const RESET_PASS_CONFIRM_URL = AUTH_URL + "/password-reset/confirm/";
const ACTIVATE_URL = AUTH_URL + "/activate/";


export const LoginApi = async (user) => {
    try {
        const response = await axios.post(
            LOGIN_URL,
            JSON.stringify({ email: user.email, password: user.pwd }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);

        return ""
    } catch (err) {
        if (!err?.response) {
            return "No Server Response";
        } else {
            return "Invalid credentials";
        }
    }
};

export const IsAuthenticated = () => {
    const token = localStorage.getItem("access_token");
    if (token === null)
        return false;

    var decoded = jwt_decode(token);
    return decoded.exp > Date.now() / 1000;
};


export const Logout = () => {
    localStorage.removeItem("access_token");
};

export const RegisterApi = async (user) => {
    try {
        const response = await axios.post(
            REGISTER_URL,
            JSON.stringify({ email: user.email, password: user.pwd, user_name: user.username, country: user.country, user_type: user.userType }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );

        return { success: true, err: null };
    } catch (err) {
        if (!err?.response) {
            return { success: false, err: ["No Server Response"] };
        } else {
            return { success: false, err: err?.response.data };
        }
    }
};

export const ResetPassApi = async (email) => {
    try {
        const response = await axios.post(
            RESET_PASS_URL,
            JSON.stringify({ email: email, }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );

        return { success: true };
    } catch (err) {
        if (!err?.response) {
            return { success: false, err: ["No Server Response"] };
        }
        return { success: false, err: ["Reset Failed"] };
    }
};

export const ResetPassConfirmApi = async (password, token) => {
    try {
        const response = await axios.post(
            RESET_PASS_CONFIRM_URL,
            JSON.stringify({ password: password, token: token }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );

        return { success: true, err: null };
    } catch (err) {
        if (!err?.response) {
            return { success: false, err: ["Try again later"] };
        }
        return { success: false, err: err?.response.data.password };
    }
};

export const ActivateAccountApi = async (uidb64, token) => {
    try {
        const response = await axios.get(
            `${ACTIVATE_URL}/${uidb64}/${token}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        return { success: true, err: null };
    } catch (err) {
        if (!err?.response) {
            return { success: false, err: ["Try again later"] };
        }
        return { success: false, err: err?.response.data.password };
    }
};